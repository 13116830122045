import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-72951c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "page-grid" }
const _hoisted_3 = { class: "filter" }
const _hoisted_4 = {
  key: 1,
  class: "list-container"
}
const _hoisted_5 = { class: "list-header" }
const _hoisted_6 = { class: "text-m-bold" }
const _hoisted_7 = { class: "sorting" }
const _hoisted_8 = { class: "list-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["CourseListHeader"], { "full-height": $setup.isLoggedIn }, null, 8, ["full-height"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["CourseListFilterBar"], {
            title: "Courses",
            data: $setup.filtersCourses,
            onUpdate: _cache[0] || (_cache[0] = (data) => $setup.filtersUpdate(data, 'course'))
          }, null, 8, ["data"]),
          _createVNode($setup["CourseListFilterBar"], {
            title: "Specialty",
            "is-more": $setup.coursesStore.isMoreSpecialities,
            "is-more-text": "More Specialties",
            data: $setup.coursesStore.specialities,
            "search-options": $setup.coursesStore.specialitiesOptions,
            "is-fetching": $setup.coursesStore.specialitiesFetching,
            "is-searchable": true,
            "search-placeholder": "Search specialities",
            onUpdate: _cache[1] || (_cache[1] = (data) => $setup.filtersUpdate(data, 'specialities')),
            onLoadMore: _cache[2] || (_cache[2] = ($event: any) => ($setup.coursesStore.getSpecialities(true))),
            onFetchSpecialtyOptions: $setup.coursesStore.fetchSpecialtyOptions,
            onUpdateFilter: _cache[3] || (_cache[3] = (id) => $setup.addNewFilter(id, 'specialities'))
          }, null, 8, ["is-more", "data", "search-options", "is-fetching", "onFetchSpecialtyOptions"]),
          _createVNode($setup["CourseListFilterBar"], {
            title: "Credits",
            "is-more": $setup.coursesStore.isMoreCredits,
            "is-more-text": "More Credits",
            data: $setup.coursesStore.credits,
            "search-options": $setup.coursesStore.creditsOptions,
            "is-fetching": $setup.coursesStore.creditsFetching,
            "is-searchable": true,
            "search-placeholder": "Search credits",
            onUpdate: _cache[4] || (_cache[4] = (data) => $setup.filtersUpdate(data, 'credits')),
            onLoadMore: _cache[5] || (_cache[5] = ($event: any) => ($setup.coursesStore.getCredits(true))),
            onFetchSpecialtyOptions: $setup.coursesStore.fetchCreditsOptions,
            onUpdateFilter: _cache[6] || (_cache[6] = (id) => $setup.addNewFilter(id, 'credits'))
          }, null, 8, ["is-more", "data", "search-options", "is-fetching", "onFetchSpecialtyOptions"])
        ]),
        ($setup.fetching)
          ? (_openBlock(), _createBlock($setup["AppLoader"], { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createTextVNode("Found: " + _toDisplayString($setup.totalCourses) + " Courses", 1),
                  ($setup.coursesStore.searchText)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" for \"" + _toDisplayString($setup.coursesStore.searchText) + "\"", 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["AppDropdown"], {
                    options: $setup.sortingOptions,
                    value: $setup.activeSortingOption,
                    onOnChange: $setup.changeSorting
                  }, null, 8, ["value"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.courses, (item) => {
                  return (_openBlock(), _createBlock($setup["CourseListItem"], {
                    key: item.id,
                    data: item
                  }, null, 8, ["data"]))
                }), 128))
              ])
            ]))
      ]),
      (!$setup.fetching && $setup.courses.length)
        ? (_openBlock(), _createBlock($setup["CourseListPagination"], {
            key: 0,
            page: $setup.currentPage,
            "total-pages": $setup.totalPages,
            onChangePage: $setup.changePage
          }, null, 8, ["page", "total-pages"]))
        : _createCommentVNode("", true)
    ])
  ]))
}