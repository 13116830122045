import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6201f890"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }
const _hoisted_2 = { class: "progress-bar" }
const _hoisted_3 = { class: "progress-bar-label" }
const _hoisted_4 = { class: "progress-bar-track" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($props.data.completedSteps.length) + " of 5", 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "progress-bar-fill",
          style: _normalizeStyle({ width: $setup.fillProgressWidth + '%' })
        }, null, 4)
      ])
    ])
  ]))
}