import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38c7a76a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "course-item" }
const _hoisted_2 = { class: "course-item-poster-backdrop" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "badges-container" }
const _hoisted_5 = {
  key: 0,
  class: "badge badge-yellow"
}
const _hoisted_6 = {
  key: 1,
  class: "badge badge-green"
}
const _hoisted_7 = { class: "course-item-content" }
const _hoisted_8 = { class: "text-m-bold" }
const _hoisted_9 = { class: "chips" }
const _hoisted_10 = {
  key: 0,
  class: "chip chip-speakers"
}
const _hoisted_11 = {
  key: 1,
  class: "chip chip-credits"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "course-item-poster",
        src: $props.data.poster?.presets['500x500'] || $props.data.poster?.url
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        ($props.data.is_featured)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Hot Topics"))
          : _createCommentVNode("", true),
        ($props.data.is_new)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "new"))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_8, _toDisplayString($props.data.title), 1),
        _createElementVNode("div", _hoisted_9, [
          ($props.data.speakers?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.data.speakers?.length) + " Speakers", 1))
            : _createCommentVNode("", true),
          ($props.data.credits?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($props.data.credits?.length) + " Credits", 1))
            : _createCommentVNode("", true)
        ]),
        ($props.data.completedSteps?.length)
          ? (_openBlock(), _createBlock($setup["CourseProgressBar"], {
              key: 0,
              class: "progress-container",
              data: $props.data
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_router_link, {
        class: "button w-full",
        to: { name: $setup.routesName.course, params: { id: $props.data.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode("View Details")
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}