import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["course-list-header", { 'full-height': $props.fullHeight }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'text-h1': $props.fullHeight, 'text-h3': !$props.fullHeight })
    }, "Explore the courses", 2),
    _createElementVNode("div", {
      class: _normalizeClass({ 'text-h4': $props.fullHeight, 'text-l-semibold': !$props.fullHeight })
    }, "Embark on your personal medical education journey.", 2)
  ], 2))
}