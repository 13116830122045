import axiosClient from "./index";
import type { ApiClientResponse } from "@/types/api/ApiClientResponse";
import type { IActivity } from "@/types/entities/IActivity";
import type { IActivityResponse } from "@/types/api/IActivityResponse";
import type { ICourseProcessing } from "@/types/entities/ICourseProcessing";
import type { ICoursePreview } from "@/types/entities/ICoursePreview";
import type { ICoursesListResponseBody } from "@/types/api/ICoursesListResponseBody";
import type { ICoursesListParams } from "@/types/api/ICoursesListParams";
import { IFilterListResponseBody } from "@/types/api/IFilterListResponseBody";

export default {
  getActivityById(courseId: number): Promise<ApiClientResponse<IActivity>> {
    return axiosClient.get(`courses/${courseId}/activity`);
  },
  getPreviewById(courseId: number): Promise<ApiClientResponse<ICoursePreview>> {
    return axiosClient.get(`courses/${courseId}/preview`);
  },
  setProcessingById(payload: {
    courseId: number;
    data: ICourseProcessing;
  }): Promise<ApiClientResponse<IActivityResponse>> {
    return axiosClient.post(
      `courses/${payload.courseId}/processing`,
      payload.data
    );
  },
  getListOfCourses(
    params: ICoursesListParams
  ): Promise<ApiClientResponse<ICoursesListResponseBody>> {
    return axiosClient.get(`courses`, { params });
  },
  getListOfCredits(params: {
    page: number;
    per_page: number;
    name?: string;
  }): Promise<ApiClientResponse<IFilterListResponseBody>> {
    return axiosClient.get(`credits`, { params });
  },
  getListOfSpecialities(params: {
    page: number;
    per_page?: number;
    name?: string;
    is_trending?: boolean;
  }): Promise<ApiClientResponse<IFilterListResponseBody>> {
    return axiosClient.get(`specialities`, { params });
  },
};
