import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3afcaf18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-selected-option" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown-options"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown", { opened: $setup.isDropdownOpened, disabled: $props.disabled }]),
    onClick: $setup.toggleDropdown
  }, [
    _createElementVNode("button", _hoisted_1, [
      _renderSlot(_ctx.$slots, "selected-option", { option: $props.value }, () => [
        _createTextVNode(_toDisplayString($props.value.label), 1)
      ], true)
    ]),
    ($setup.isDropdownOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.options, (option) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["dropdown-options-item", { active: option.value === $props.value.value }]),
              key: option.value,
              onClick: _withModifiers(($event: any) => ($setup.selectOption(option)), ["stop"])
            }, [
              _renderSlot(_ctx.$slots, "option", { option: option }, () => [
                _createTextVNode(_toDisplayString(option.label), 1)
              ], true)
            ], 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}