import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import type { IFilter } from "@/types/entities/IFilter";
import coursesApi from "@/services/api/coursesApi";
import { useRoute } from "vue-router";

export const useCoursesStore = defineStore("courses", () => {
  const route = useRoute();
  const creditsPagination = reactive({
    page: 1,
    per_page: 20,
    total_pages: 1,
  });
  const credits = ref<IFilter[]>([]);
  const creditsFetching = ref<boolean>(false);
  const isMoreCredits = computed<boolean>(
    () => creditsPagination.page <= creditsPagination.total_pages
  );
  const getCredits = (isMore = false): void => {
    if (!isMore && credits.value.length) {
      return;
    }
    creditsFetching.value = true;
    coursesApi
      .getListOfCredits(creditsPagination)
      .then((res) => {
        const idsFromQuery = route.query["credits_ids"]
          ? String(route.query["credits_ids"]).split(",").map(Number)
          : [];
        credits.value = [...credits.value, ...res.data.data.list].map((c) => ({
          ...c,
          checked: c.checked || idsFromQuery.includes(c.id),
        }));
        creditsPagination.total_pages = res.data.data.total_pages;
        creditsPagination.page = creditsPagination.page + 1;
      })
      .finally(() => {
        creditsFetching.value = false;
      });
  };
  const creditsOptions = ref<IFilter[]>([]);
  const fetchCreditsOptions = (
    search: string,
    loading: (v: boolean) => void
  ): void => {
    loading(true);
    coursesApi
      .getListOfCredits({
        page: 1,
        per_page: 20,
        name: search,
      })
      .then((res) => {
        const idsFromQuery = route.query["credits_ids"]
          ? String(route.query["credits_ids"]).split(",").map(Number)
          : [];
        creditsOptions.value = res.data.data.list.map((c) => ({
          ...c,
          checked: c.checked || idsFromQuery.includes(c.id),
        }));
      })
      .finally(() => {
        loading(false);
      });
  };

  const specialitiesPagination = reactive({
    page: 1,
    per_page: 20,
    total_pages: 1,
  });
  const specialities = ref<IFilter[]>([]);
  const specialitiesFetching = ref<boolean>(false);
  const isMoreSpecialities = computed<boolean>(
    () => specialitiesPagination.page <= specialitiesPagination.total_pages
  );
  const getSpecialities = (isMore = false): void => {
    if (!isMore && specialities.value.length) {
      return;
    }
    specialitiesFetching.value = true;
    coursesApi
      .getListOfSpecialities(specialitiesPagination)
      .then((res) => {
        const idsFromQuery = route.query["specialities_ids"]
          ? String(route.query["specialities_ids"]).split(",").map(Number)
          : [];
        specialities.value = [...specialities.value, ...res.data.data.list].map(
          (c) => ({
            ...c,
            checked: c.checked || idsFromQuery.includes(c.id),
          })
        );
        specialitiesPagination.total_pages = res.data.data.total_pages;
        specialitiesPagination.page = specialitiesPagination.page + 1;
      })
      .finally(() => {
        specialitiesFetching.value = false;
      });
  };

  const specialitiesOptions = ref<IFilter[]>([]);
  const fetchSpecialtyOptions = (
    search: string,
    loading: (v: boolean) => void
  ): void => {
    loading(true);
    coursesApi
      .getListOfSpecialities({
        page: 1,
        per_page: 20,
        name: search,
      })
      .then((res) => {
        const idsFromQuery = route.query["specialities_ids"]
          ? String(route.query["specialities_ids"]).split(",").map(Number)
          : [];
        specialitiesOptions.value = res.data.data.list.map((c) => ({
          ...c,
          checked: c.checked || idsFromQuery.includes(c.id),
        }));
      })
      .finally(() => {
        loading(false);
      });
  };

  const updateFilters = () => {
    const idsFromQueryCredits = route.query["credits_ids"]
      ? String(route.query["credits_ids"]).split(",").map(Number)
      : [];
    creditsOptions.value = creditsOptions.value.map((c) => ({
      ...c,
      checked: idsFromQueryCredits.includes(c.id),
    }));
    credits.value = credits.value.map((c) => ({
      ...c,
      checked: idsFromQueryCredits.includes(c.id),
    }));
    const idsFromQuerySpecialities = route.query["specialities_ids"]
      ? String(route.query["specialities_ids"]).split(",").map(Number)
      : [];
    specialitiesOptions.value = specialitiesOptions.value.map((c) => ({
      ...c,
      checked: idsFromQuerySpecialities.includes(c.id),
    }));
    specialities.value = specialities.value.map((c) => ({
      ...c,
      checked: idsFromQuerySpecialities.includes(c.id),
    }));
  };

  watch(
    () => route.query,
    () => {
      updateFilters();
    }
  );

  const searchText = ref<string>("");
  watch(
    () => route.name,
    (newV) => {
      if (newV) {
        searchText.value = "";
      }
    }
  );

  return {
    credits,
    creditsFetching,
    getCredits,
    isMoreCredits,
    creditsPagination,
    creditsOptions,
    fetchCreditsOptions,
    specialities,
    specialitiesFetching,
    getSpecialities,
    isMoreSpecialities,
    fetchSpecialtyOptions,
    specialitiesOptions,
    specialitiesPagination,
    searchText,
  };
});
