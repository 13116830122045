import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-018e8fb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-wrapper" }
const _hoisted_2 = { class: "pagination-list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["prev", { disabled: $props.page === 1 }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.page !== 1 && $setup.goToPage($props.page - 1)))
    }, "Previous", 2),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.totalPages, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["pagination-list-item", { active: item === $props.page }]),
          key: item,
          onClick: ($event: any) => (item !== $props.page && $setup.goToPage(item))
        }, _toDisplayString(item), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["next", { disabled: $props.page === $props.totalPages }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($props.page !== $props.totalPages && $setup.goToPage($props.page + 1)))
    }, "Next", 2)
  ]))
}