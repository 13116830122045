import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-002c5e85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-user-panel" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "header-user-avatar"
}
const _hoisted_4 = {
  class: "header-user-dropdown-menu",
  ref: "userDropdownMenuRef"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "header-user-dropdown-menu-item-label" }
const _hoisted_7 = { class: "header-user-dropdown-menu-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["header-user-dropdown", { opened: $setup.isUserDropdownOpened }])
    }, [
      _createElementVNode("button", {
        class: "header-user-dropdown-info",
        ref: "userDropdownRef",
        onClick: $setup.onUserInfo
      }, [
        ($setup.userStore.userData?.avatar)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "header-user-avatar",
              src: $setup.userStore.userData?.avatar?.presets['150x150'] || $setup.userStore.userData?.avatar?.url,
              alt: "avatar image"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(`${$setup.userStore.userData?.first_name?.charAt(0)}${$setup.userStore.userData?.last_name?.charAt(0)}`), 1))
      ], 512),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.menuOptions, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.label
          }, [
            (!!item.action)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "header-user-dropdown-menu-item",
                  onClick: ($event: any) => ($setup.onMenuItem(item.action))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["header-user-dropdown-menu-item-icon", item.iconName])
                  }, null, 2),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(item.label), 1)
                ], 8, _hoisted_5))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "header-user-dropdown-menu-item",
                  to: { name: item.routeName }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["header-user-dropdown-menu-item-icon", item.iconName])
                    }, null, 2),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(item.label), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ], 64))
        }), 64))
      ], 512)
    ], 2)
  ]))
}