import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a0963aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = {
  key: 0,
  class: "main-layout-content"
}
const _hoisted_3 = {
  key: 1,
  class: "main-layout-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AppHeader"]),
    ($setup.courseStore.search)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["AppGlobalSearch"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ])),
    _createVNode($setup["AppFooter"])
  ]))
}